export const CANDIDATE_PORTAL_BASE_URL = {
    alpha: {
        AE : "https://alpha.careers.warehousejobsuae.com/",
        SA : "https://alpha.careers.warehousejobsksa.com/",
        EG : "https://alpha.careers.warehousejobseg.com/",
        IN : "https://alpha.careers.hvhindia.in/"
    },
    beta: {
        SA : "https://beta.careers.warehousejobsksa.com/",
        EG : "https://beta.careers.warehousejobseg.com/",
        AE : "https://beta.careers.warehousejobsuae.com/",
        IN : "https://beta.careers.hvhindia.in/"
    },
    gamma: {
        SA : "https://gamma.careers.warehousejobsksa.com/",
        AE : "https://gamma.careers.warehousejobsuae.com/",
        EG : "https://gamma.careers.warehousejobseg.com/",
        IN : "https://gamma.careers.hvhindia.in/"
    },
    prod: {
        SA : "https://careers.warehousejobsksa.com/",
        AE : "https://careers.warehousejobsuae.com/",
        EG : "https://careers.warehousejobseg.com/",
        IN : "https://careers.hvhindia.in/"
    }
}
export let isMENACountry = () =>{
    const countryCode = sessionStorage.getItem('countryCode');
    return countryCode === "AE" || countryCode === "EG" || countryCode === "SA";
}

export function getCandidatePortalUrl(stage){
    const countryCode = sessionStorage.getItem('countryCode');
    return CANDIDATE_PORTAL_BASE_URL[stage][countryCode];
}

// SR Grievance Ingress Point SOP: https://quip-amazon.com/OKwIAuzab2cy/SR-Grievance-Ingress-Point-SOP
export const GRIEVANCE_CHATBOT_BASE_URL = {
    alpha: {
        SA : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        AE : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        EG : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
  },
    beta: {
        SA : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        AE : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        EG : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
   },
    gamma: {
        SA : "https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        AE : "https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        EG : "https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
    },
    prod: {
        SA : "https://mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        AE : "https://mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
        EG : "https://mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO",
    }
}

export function getGrievanceChatbotUrl(){
    const stage = sessionStorage.getItem('stage');
    const countryCode = sessionStorage.getItem('countryCode');
    return GRIEVANCE_CHATBOT_BASE_URL?.[stage]?.[countryCode];
}