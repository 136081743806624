import React, { useState, useEffect } from 'react';
import HeaderTab from './Pages/CommonComponents/HeaderTab';
import HomePage from './Pages/HomePage/HomePage';
import { init } from './api/axios';
import { CandidateService } from './Services/CandidateService';
import FullPageCenteredSpinner from './Pages/CommonComponents/FullPageCenteredSpinner';
import { withAlertSnackBar } from './Components/MessageBanner/AlertSnackBarHOC';
import constants from './Utils/Constants';
import { getProperName } from './Utils/Util';
import { View } from '@amzn/stencil-react-components/layout';
import { Modal } from '@amzn/stencil-react-components/modal';
import { Col } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Row } from '@amzn/stencil-react-components/layout';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { InputWrapper, Select } from '@amzn/stencil-react-components/form';
import { Trans, useTranslation } from 'react-i18next';
import { PhoService } from './Services/PhoServices';
import { PHO_LANGUAGES } from './Enums/language';
import { H2 } from '@amzn/stencil-react-components/text';
import { getCandidatePortalUrl, getGrievanceChatbotUrl, isMENACountry } from './Utils/CountryConstant';
import { MessageBanner, MessageBannerLink } from '@amzn/stencil-react-components/message-banner';
import { IconExternalLink, IconSize } from '@amzn/stencil-react-components/icons';
import { useLocalStorage } from './hooks/useLocalStorage';

const default_languages = [{ id: 'en-US', name: 'English' }];

function App(props: any) {
  const [loader, setLoader] = useState(true);
  const [language, setLanguage] = useState(default_languages[0]);
  const [languages, setLanguages] = useState(default_languages);
  const [isOpenModal, setIsOpenModel] = useState(false);
  const [showModules, setShowModules] = useState(false);
  const { i18n, ready } = useTranslation();

  const grievanceChatbotUrl = getGrievanceChatbotUrl();

  const [grievanceFeatureFlag, setGrievanceFeatureFlag] = useLocalStorage(constants.FEATURE_FLAGS.GRIEVANCE_REPORTING, false);

  //call vendor portal proxy to authenticate the user & if fails then redirect it to candidatePortal!
  useEffect(() => {
    const pageUrl = new URL(document.URL);
    const paths = pageUrl.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    if (sessionStorage.getItem('idtoken') === 'null' || sessionStorage.getItem('accesstoken') === null) {
      sessionStorage.setItem('site', paths.split('/')[1]);
      sessionStorage.setItem('role', paths.split('/')[2]);
      sessionStorage.setItem('idtoken', searchParams.get('idtoken') as string);
      sessionStorage.setItem('accesstoken', searchParams.get('accesstoken') as string);
      sessionStorage.setItem('phoId', searchParams.get('phoId') as string);
      sessionStorage.setItem('candidateId', searchParams.get('candidateId') as string);
      sessionStorage.setItem('applicationId', searchParams.get('applicationId') as string);
      reportUsername(searchParams.get('candidateId') as string);
    }

    async function fetchData() {
      setLoader(true);
      if (init) await init();
      await CandidateService.verifySession()
        .then(async (data) => {
          const session = data.data.session;
          if (!session) redirectToCandidatePortal(sessionStorage.getItem('stage'));
          else {
            await getCandidateProfile({
              email: sessionStorage.getItem('candidateId'),
            });
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          /* istanbul ignore next */
          window.setTimeout(() => redirectToCandidatePortal(sessionStorage.getItem('stage')), 1500);
        });
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const getAppConfigData = async () => {
    PhoService.getAppConfig()
      .then((res) => {
        let languageT = Object.keys(res.data.PHO_LANGUAGES).map((key) => ({
          id: key,
          name: PHO_LANGUAGES[key as keyof typeof PHO_LANGUAGES],
        }));
        localStorage.setItem('PHO_LANGUAGES', JSON.stringify(languageT));
        // @ts-ignore
        setLanguages(languageT);

        setGrievanceFeatureFlag(!!res?.data?.FEATURE_FLAG_ENABLE_GRIEVANCE_REPORTING);
      })
      .catch((err) => {
        /* istanbul ignore next */
        props.snackbarShowMessage(`${constants.ERROR.APP_CONFIG_FETCH_ERROR}`, 'error', '3000');
      });
  };

  const getCandidateProfile = async (body: { email: string | null }) => {
    setLoader(true);
    CandidateService.getCandidateProfileByEmail(body)
      .then((res) => {
        const body = res && res.data;
        setLoader(false);
        sessionStorage.setItem('candidateName', getProperName(body));
        const searchParams = new URLSearchParams(window.location.search);
        getAppConfigData();
        if (!searchParams.get('locale')) {
          setIsOpenModel(true);
        }
        setShowModules(true);
      })
      .catch((err) => {
        setLoader(false);
        props.snackbarShowMessage(`${constants.ERROR.PROFILE_ERROR}`, 'error', '3000');
      });
  };

  const redirectToCandidatePortal = (stage: any) => {
    props.snackbarShowMessage(`${constants.ERROR.VERIFY_SESSION_FAILS}`, 'error');
    window.setTimeout(() => {
      // @ts-ignore
      window.location.href = getCandidatePortalUrl(stage);
      sessionStorage.clear();
    }, 2500);
  };

  /* istanbul ignore next */
  const changeLanguage = () => {
    if (language.id.replace('_', '-') !== i18n.language) {
      window.location.replace(window.location.href.replace('?phoId', `?locale=${language.id}&phoId`));
    } else {
      setIsOpenModel(false);
      setShowModules(true);
    }
  };

  if (!ready || loader) {
    /* istanbul ignore next */
    return <FullPageCenteredSpinner size="m" message="Checking user session..." />;
  }

  return (
    <div style={{ height: '100%' }}>
      <View style={{ height: '56px' }}>{showModules && <HeaderTab />}</View>
      {isMENACountry() && grievanceFeatureFlag && showModules && (
        <MessageBanner>
          <Trans i18nKey="PHO-grievance.value">
            {
              'You deserve fair and equal recruitment practices. However, if you experience a violation during the hiring process, '
            }
            <MessageBannerLink href={grievanceChatbotUrl} target="_blank" rel="noopener noreferrer" icon={<IconExternalLink size={IconSize.ExtraSmall} />}>
              report an issue
            </MessageBannerLink>
          </Trans>
        </MessageBanner>
      )}
      <View className="content">
        {showModules && <HomePage />}
        <Modal
          isOpen={isOpenModal}
          shouldCloseOnClickOutside={false}
          close={() => {
            setIsOpenModel(false);
          }}
        >
          <Col backgroundColor="neutral0" gridGap="S500" padding="S500">
            <H2>Language selection</H2>
            <Text id="text-test-id">Pick the language you&apos;d like to take your pre-hire orientation in</Text>
            <InputWrapper labelText="Language selection" id="language-selector" aria-required={true}>
              {(inputProps: any) => (
                <Select
                  key={JSON.stringify(languages)}
                  dataTestId="language-selector"
                  options={languages}
                  renderOption={(item: any) => <View dataTestId={'language-selector-' + item.id}>{item.name}</View>}
                  valueAccessor={(item: any) => item.name}
                  onChange={(item: any) => {
                    setLanguage(item);
                  }}
                  value={language}
                  {...inputProps}
                />
              )}
            </InputWrapper>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button onClick={changeLanguage} variant={ButtonVariant.Primary}>
                Confirm
              </Button>
            </Row>
          </Col>
        </Modal>
      </View>
    </div>
  );
}
/* istanbul ignore next */
function reportUsername(username: string): void {
  if (username !== null && username !== '') {
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: username,
      },
    });
    document.dispatchEvent(authenticatedUserEvent);
  }
}

export default withAlertSnackBar(App);
