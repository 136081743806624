let constants = {
    CONTENT_PREFIX: "content#",
    INPUT_TYPES: {
        SINGLE_SELECT: "SINGLE_SELECT",
        USER_INPUT_SINGLE_SELECT: "USER_INPUT_SINGLE_SELECT",
        MULTI_SELECT: "MULTI_SELECT"
    },
    TRANSLATE:{
        PHO_LOCALE: "pho-locale"
    },
    PHO: {
        STAGES: {
            ASSIGNED: 'PHO Assigned',
            IN_PROGRESS: 'PHO In-Progress',
            COMPLETED: 'PHO Completed'
        }, STATUS: {
            TODO: 'TODO',
            IN_PROGRESS: 'IN_PROGRESS',
            COMPLETED: 'COMPLETED'
        },
        MODULES:{
            WELCOME:"Welcome",
            SAFETY:"Safety",
            ABOUT_ALFA:"About ALFA",
            UNDERSTAND_YOUR_ROLE:"Understand Your Role",
            PAY_AND_BENEFITS:"Pay And Benefits",
            LOCATION_AND_TRANSPORT:"Location And Transportation",
            SHIFT_TIME_AND_WORK_HOURS:"Shift Timing And Work Hours",
            FEEDBACK:"Must Know"
        },
        MODULES_PRIORITY:{
            "Welcome":0,
            "Safety":1,
            "About ALFA":2,
            "Understand Your Role":3,
            "Pay And Benefits":4,
            "Shift Timing And Work Hours":5,
            "Location And Transportation":6,
            "Must Know":10
        },
        CONTENT_TYPE:{
            SINGLE_SELECT:"SINGLE_SELECT",
            MULTI_SELECT:"MULTI_SELECT",
            USER_INPUT_SINGLE_SELECT:"USER_INPUT_SINGLE_SELECT"
        },
        UPDATE_CANDIDATE_ENTITY_OPERATION: "UPDATE_CANDIDATE"
    },
    SITE: {
        TITLE: 'HVH Pre-hire orientation Portal',
        BASE_URL: process.env.REACT_APP_BASE_URL,
        VERSION: process.env.REACT_APP_VERSION,
    },
    BUTTON: {
        CREATE: 'Create new',
        ACCEPT: 'Accept',
        DECLINE: 'Decline requisition',
        FILTER: 'Filter',
        DELETE: 'Delete',
        CANCEL: 'Cancel',
    },
    ERROR: {
        VERIFY_SESSION_FAILS: 'User session is not verified please login in candidate portal & redirect back...',
        PROFILE_ERROR: 'Something went wrong, unable to fetch profile data',
        APP_CONFIG_FETCH_ERROR: 'Something went wrong.',
        TRANSLATED_RESOURCE_IMPORTING_FAILED: "Something went wrong while translating resources!"
    },
    AUTH: {
        SIGNIN_SUCCESS: 'Sign-in Successfull',
        SIGNIN_FAILED: 'Sign-in Failed. Invalid Credentials',
        PASSWORD_RESET_SUCCESS: 'Password successfully reset. Please use your new credentials to login',
        PASSWORD_RESET_FAILED: 'Password reset failed',
        PASSWORD_CHANGE_FAILED: 'Failed to change password',
        PASSWORD_CHANGE_SUCCESS: 'Password successfully changed. Please use your new credentials the next time you login',
        OTP_SENT_SUCCESS: 'OTP successfully sent to your registered email. Please enter it to change your password',
        OTP_SENT_FAILED: 'Failed to send OTP to your registered email',
        OLD_PASSWORD_INVALID: 'Old password invalid',
        APP_CONFIG_FAILED: 'Failed to load the app config data'
    },
    SPECTROMETER: {
        APP_NAME: "HVHPhoUI",
        SITE_NAME: "HVHPhoUI",
        STAGE: "stage",
    },
    CUSTOM_MODULES: {
        PHO_CUSTOM_WELCOME_MODULE: "PHO-CUSTOM-WELCOME-MODULE"
    },
    // Local Storage keys for Feature Flags
    FEATURE_FLAGS: {
        GRIEVANCE_REPORTING: "FEATURE_FLAG_ENABLE_GRIEVANCE_REPORTING"
    }
}

export default constants